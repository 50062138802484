.articleimg {
  width: 36rem;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.articletextbox {
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: space-between;
  padding: 1.6rem 0;
}

.headertertiary {
  text-decoration: none;
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-bottom: 1.8rem;
  justify-self: start;
}

.articledescription {
  font-size: 1.3rem;
  line-height: 1.6;
  letter-spacing: 0.02rem;
  font-weight: 300;
  margin-bottom: 1.4rem;
}

.smalprint {
  display: flex;
  justify-content: space-between;
}

.publishedin {
  display: flex;
  align-items: center;
}

.tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-right: 1rem;
}

.bitcoin {
  background-color: #f7931a;
  color: #fff;
  padding: 0.5rem;
  border-radius: 9px;
}

.philolaw {
  background-color: #3f2402;
  color: #fff;
  padding: 0.5rem;
  border-radius: 9px;
}

.covid {
  background-color: #ab02b1;
  color: #fff;
  padding: 0.5rem;
  border-radius: 9px;
}

.money {
  background-color: #0f770b;
  color: #fff;
  padding: 0.5rem;
  border-radius: 9px;
}

.philosophy {
  background-color: #8c1515;
  color: #fff;
  padding: 0.5rem;
  border-radius: 9px;
}

.law {
  background-color: #11088d;
  color: #fff;
  padding: 0.5rem;
  border-radius: 9px;
}

.truthpill {
  background-color: #c20909;
  color: #fff;
  padding: 0.5rem;
  border-radius: 9px;
}

.fiction {
  background-color: #4a06a3;
  color: #fff;
  padding: 0.5rem;
  border-radius: 9px;
}

@media (max-width: 950px) {
  .wholeLink {
    height: 30rem;
  }

  .articleimg {
    max-width: 50rem;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }

  .headertertiary {
    text-decoration: none;
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 1rem;
    justify-self: start;
  }

  .articledescription {
    font-size: 1rem;
    line-height: 1.4;
    letter-spacing: 0.02rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  .tags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-right: 1rem;
    font-size: 1rem;
  }
}

@media (max-width: 900px) {
  .articleimg {
    max-width: 30rem;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }

  .articledescription {
    font-size: 0.9rem;
    line-height: 1.2;
    letter-spacing: 0.02rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  .tags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.1rem;
    margin-right: 0.8rem;
    font-size: 1rem;
  }

  .publishedin {
    display: flex;
    font-size: 0.8rem;
    align-items: center;
  }
}

@media (max-width: 825px) {
  .wholeLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .articleimgbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .articleimg {
    max-width: 95%;
    border-radius: 9px;
  }

  .articledescription {
    font-size: 0.9rem;
    line-height: 1.2;
    letter-spacing: 0.02rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  .tags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.1rem;
    margin-right: 0.8rem;
    font-size: 1rem;
  }

  .publishedin {
    display: flex;
    font-size: 0.8rem;
    align-items: center;
  }
}
