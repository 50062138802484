.header {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 10.2rem;
  padding: 0 1.6rem 0 6.7rem;
  background-color: rgba(90, 90, 90, 0.4);
  z-index: 998;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alldark {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 10.2rem;
  padding: 0 1.6rem 0 6.7rem;
  background-color: rgba(66, 66, 66, 0.281);
  z-index: 998;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 7.8rem;
  width: 7.8rem;
  border-radius: 50%;
}

.options {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
