* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: "Merriweather", serif;
  line-height: 1;
  font-weight: 400;
  color: #555;
  /* position: relative; */
}

.alldark {
  background-color: rgb(26, 26, 26);
  color: #fff;
}
