.main {
  margin: 0 auto;
  margin-top: 12rem;
  margin-bottom: 6.4rem;
  width: 68rem;
  position: relative;
}

.temp {
  background-color: black;
  color: #fff;
  height: 500px;
  width: 500px;
}

.input {
  font-family: inherit;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 1.3rem;
  color: #000;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  resize: none;
}

.input:focus::placeholder {
  color: transparent;
}

.title {
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 4.2rem;
  letter-spacing: -0.08rem;
  margin-bottom: 3.6rem;
}

.submit {
  position: fixed;
  top: 30%;
  right: 5%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* IMAGE */

.imageInputBox {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  margin-bottom: 3.2rem;
  margin-top: 18rem;
}

.imageBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36rem;
  overflow: hidden;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  margin-bottom: 6.2rem;
}

.fileImage::-webkit-file-upload-button {
  visibility: hidden;
}
.fileImage::before {
  content: "Image";
  display: inline-block;
  background: linear-gradient(to bottom, #f9f9f9, #e3e3e3);
  border: 6px solid #999;
  border-radius: 9px;
  padding: 8rem 8rem;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 2.6rem;
}
.fileImage:hover::before {
  border-color: black;
}
.fileImage:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
