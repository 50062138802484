.menurelative {
  position: relative;
}

.menunav {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  height: 60vh;
  background-color: rgba(90, 90, 90, 0.4);

  border-left: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in;
  flex-direction: column;
  z-index: 1;

  /* Have menu be closed */
  pointer-events: none;
  visibility: none;
  transform: translateX(100%);
}

.menunavopen {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  height: 60vh;
  background-color: rgba(255, 255, 255, 0.99);

  border-left: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in;
  flex-direction: column;
  z-index: 1;

  pointer-events: auto;
  visibility: visible;
  transform: translateX(0);
}

.menunavlist {
  list-style: none;
  display: flex;
  gap: 6.4rem;
  align-items: center;
  flex-direction: column;
  margin-top: 6.4rem;
}

.menunavlink:link,
.menunavlink:visited {
  text-decoration: none;
  color: #444;
  font-size: 2.4rem;
  font-weight: 300;
}

.menunavlink:hover,
.menunavlink:active {
  color: #003da5;
}

/* DarkMode */

.menunavopendark {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  height: 60vh;
  background-color: rgb(26, 26, 26);
  border-left: 1px solid #4e4e4e;
  border-bottom: 1px solid #4e4e4e;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in;
  flex-direction: column;
  z-index: 1;

  pointer-events: auto;
  visibility: visible;
  transform: translateX(0);
}

.menunavlinkdark:link,
.menunavlinkdark:visited {
  text-decoration: none;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 300;
}

.menunavlinkdark:hover,
.menunavlinkdark:active {
  color: #003da5;
}

@media (max-width: 934px) {
  .menunav {
    width: 38%;
    height: 40vh;
  }

  .menunavopen {
    width: 38%;
    height: 40vh;
  }

  .menunavopendark {
    width: 38%;
    height: 40vh;
  }
}

@media (max-width: 836px) {
  .menunav {
    width: 100%;
    height: 10vh;
  }

  .menunavopen {
    width: 100%;
    height: 10vh;
  }

  .menunavopendark {
    width: 100%;
    height: 10vh;
  }

  .menunavlist {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4.4rem;
    margin-top: 1rem;
  }

  .menunavlink:link,
  .menunavlink:visited {
    display: inline-block;
    font-size: 2rem;
  }

  .menunavlinkdark:link,
  .menunavlinkdark:visited {
    font-size: 2rem;
  }
}

@media (max-width: 720px) {
  .menunavlink:link,
  .menunavlink:visited {
    display: inline-block;
    font-size: 1.8rem;
  }

  .menunavlinkdark:link,
  .menunavlinkdark:visited {
    font-size: 1.8rem;
  }
}
@media (max-width: 654px) {
  .menunavlink:link,
  .menunavlink:visited {
    display: inline-block;
    font-size: 1.5rem;
  }

  .menunavlinkdark:link,
  .menunavlinkdark:visited {
    font-size: 1.5rem;
  }
}
@media (max-width: 654px) {
  .menunav {
    width: 100%;
    height: 25vh;
  }

  .menunavopen {
    width: 100%;
    height: 25vh;
  }

  .menunavopendark {
    width: 100%;
    height: 25vh;
  }
}
