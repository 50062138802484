.button {
  /* background-color: none; */
  border: none;
  border-radius: 9px;
  font-size: 6.4rem;
  color: #fff;
  height: 7.6rem;
  width: 7.6rem;
  align-content: center;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  /* background-color: inherit; */
  background-image: linear-gradient(
    135deg,
    rgba(85, 85, 85, 0.6),
    rgba(212, 212, 212, 0.8)
  );
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.24);
  cursor: pointer;
  /* display: none; */
}

.button:hover {
  background-image: linear-gradient(
    135deg,
    rgba(68, 68, 68, 0.6),
    rgba(190, 190, 190, 0.8)
  );
  box-shadow: 0 2.6rem 5.2rem rgba(0, 0, 0, 0.24);
}
/* button Z */

.buttonZ {
  /* background-color: none; */
  border: none;
  border-radius: 9px;
  font-size: 6.4rem;
  color: #fff;
  height: 7.6rem;
  width: 7.6rem;
  align-content: center;

  display: flex;
  align-items: center;
  justify-content: center;

  /* background-color: inherit; */
  background-image: linear-gradient(
    135deg,
    rgba(85, 85, 85, 0.6),
    rgba(212, 212, 212, 0.8)
  );
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.24);
  cursor: pointer;
  /* display: none; */
}

.buttonZ:hover {
  background-image: linear-gradient(
    135deg,
    rgba(68, 68, 68, 0.6),
    rgba(190, 190, 190, 0.8)
  );
  box-shadow: 0 2.6rem 5.2rem rgba(0, 0, 0, 0.24);
}

/* darkbutton */
.buttondark {
  /* background-color: none; */
  border: none;
  border-radius: 9px;
  font-size: 6.4rem;
  color: #fff;
  height: 7.6rem;
  width: 7.6rem;
  align-content: center;

  display: flex;
  align-items: center;
  justify-content: center;

  /* background-color: inherit; */
  background-image: linear-gradient(
    135deg,
    rgb(26, 26, 26),
    rgba(63, 63, 63, 0.89)
  );
  color: #fff;

  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.24);
  cursor: pointer;
  z-index: 99;
  /* display: none; */
}

.buttondark:hover {
  background-image: linear-gradient(
    135deg,
    rgba(28, 28, 28, 0.952),
    rgba(80, 80, 80, 0.932)
  );
  box-shadow: 0 2.6rem 5.2rem rgba(0, 0, 0, 0.24);
}

/* Dark buttonZ */

.buttondarkZ {
  /* background-color: none; */
  border: none;
  border-radius: 9px;
  font-size: 6.4rem;
  color: #fff;
  height: 7.6rem;
  width: 7.6rem;
  align-content: center;

  display: flex;
  align-items: center;
  justify-content: center;

  /* background-color: inherit; */
  background-image: linear-gradient(
    135deg,
    rgb(26, 26, 26),
    rgba(63, 63, 63, 0.89)
  );
  color: #fff;

  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.24);
  cursor: pointer;
  /* display: none; */
}

.buttondarkZ:hover {
  background-image: linear-gradient(
    135deg,
    rgba(28, 28, 28, 0.952),
    rgba(80, 80, 80, 0.932)
  );
  box-shadow: 0 2.6rem 5.2rem rgba(0, 0, 0, 0.24);
}
