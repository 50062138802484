.main {
  margin: 0 auto;
  margin-top: 10.2rem;
  margin-bottom: 6.4rem;
  width: 80rem;
}

.mainheader {
  text-align: center;
  font-weight: 700;
  font-size: 4.8rem;

  padding-top: 7.2rem;
  padding-bottom: 9.6rem;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.control {
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
}
