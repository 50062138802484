.input {
  font-family: inherit;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 1.3rem;
  color: #000;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.input:focus::placeholder {
  color: transparent;
}

.paragraphBox {
  position: relative;
}

.paragraphOptions {
  display: none;
}

.paragraphOptionsVisible {
  position: absolute;
  left: -9%;
  position: 50%;
  /* transform: translateY(50%); */
  bottom: 0%;
  background-color: none;
  border: 2px solid #555;
  background-color: #fff;
  box-shadow: none;
  color: #555;

  height: 5rem;
  width: 5rem;
  border-radius: 50%;
}

.paragraphOptionsVisible:hover,
.paragraphOptionsVisible:hover {
  color: #000;
}

.optionsBox {
  display: flex;
  gap: 2rem;
}

.optionButtons {
  background-color: none;
  border: 2px solid #555;
  background-color: #fff;
  box-shadow: none;
  color: #555;
  padding: 1rem;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;

  margin-top: 3.2rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.optionButtons:hover {
  background-color: #fff;
  box-shadow: none;
  color: #000;
  border: 2px solid #000;
  cursor: pointer;
}

.icon {
}
